<script>
import 'moment/locale/ko';
import EventBus from "@/utils/CommonEventBus";
import moment from 'moment';
import { Message } from 'element-ui';
import store from '@/store';
;

var basicSwiper = null;
var swiper = null;
var swiper2 = null;
var basicSwiper2 = null;
var fullscreenFlag= null;


export default {
  data: function () {
    return {
      publishpath: process.env.BASE_URL,
      invitationThemeFontList:[{title:'제주명조',value:'jejumyeongjo'}, {title:'나눔스퀘어네오',value:'nanumsquareneo'}, {title:'김정철명조',value:'kimjungchulmyungjo'}, {title:'부크크명조',value:'bookkmyungjo'}],
      invitationThemeColorList:['gray', 'pink', 'yellow', 'green'],
      invitationThemeFontSizeList:[{title:'보통',value:'none'}, {title:'크게',value:'size1'}, {title:'더크게',value:'size2'}],

      invitationMainTypeList:[{title:'기본',value:'basic'}, {title:'아치형',value:'arch'}, {title:'채우기',value:'fill'}],
      invitationMainSubTypeList:['type1', 'type2', 'type3', 'type4', 'type5'],
      subScript: (() => {
        
        return {
          deviceChk : function(){
            // 디바이스 체크
            function isTouchscreen() { // iPad 접속 OS13부터 Macintosh으로 인식
              return (navigator.maxTouchPoints > 0) ? true : false;
            }

            if(/Android/i.test(navigator.userAgent)) {
              if(isTouchscreen()){
                _device = 'android';
              }else{
                _device = 'pc';
              }
            }

            // 브라우저 체크
            var agent = navigator.userAgent.toLowerCase(),
                name = navigator.appName;

            if(name === 'Microsoft Internet Explorer' || agent.indexOf('trident') > -1 || agent.indexOf('edg/') > -1) {
              if(isTouchscreen()){
                _device = 'mobileIe';
              }else{
                _device = 'pcIe';
              }
              _browser = 'ie';
              $("html").addClass("ie");
            }
            else if(agent.indexOf('Chrome') > -1 || window.navigator.userAgent.indexOf('CriOS') > -1) { // Chrome
              if(isTouchscreen()){
                _device = 'mobileChrome';
              }else{
                _device = 'pcChrome';
              }
              _browser = 'chrome';
              $("html").addClass("chrome");
            }
            else if(agent.indexOf('safari') > -1) { // Safari
              if(isTouchscreen()){
                _device = 'mobileSafari';
              }else{
                _device = 'pcSafari';
              }
              _browser = 'safari';
              $("html").addClass("safari");
            }
            else if(agent.indexOf('firefox') > -1) { // Firefox
              _browser = 'firefox';
            }

          },
          init: function(){
            
          },
          pageInitFn: function(){
            // scrollMotion
            scrollMotionTrigger();
          },
          commonMotion: function() {
            
            //this.themeFixed();
            
            // 아코디언
            $(".acco-list").each(function(){
              if($(this).hasClass("on")){
                $(this).find(".acco-con").show();
              }

              $(this).find(".acco-tit").off().on("click", function(e){
                if($(e.target).parents(".form-checkbox").size() == 0){
                  if(!$(this).parents(".acco-list").hasClass("on")){
                    $(this).parents(".acco-list").addClass("on");
                    $(this).parents(".acco-list").find(".acco-con").stop(true, true).slideDown();
                    if($(e.target).attr('value')==='address'){
                      EventBus.$emit('changeMarketPosition2')
                    }
                  }else{
                    $(this).parents(".acco-list").removeClass("on");
                    $(this).parents(".acco-list").find(".acco-con").stop(true, true).slideUp();
                  }
                }

                
              })
            });

            // 테마
            /*
            $(".color-chip-box .color-chip").each(function(){
              $(this).on("click", function(){
                if(!$(this).hasClass("on")){
                  $(".color-chip-box .color-chip").removeClass("on");
                  $(this).addClass("on");
                }
              })
            });
            */
            
            if($("#sortable").size() > 0){

              $("#sortable").sortable({
                axis:'y',
                // label 태그로 핸들링 
                start: function(e, ui) {
                  // 이동 시킬 아이템 클릭 시
                  $(this).attr('data-previndex', ui.item.index()); // 기존 순서값을 data-previndex에 저장 
                },
                update: function(event, ui){
                  var oldOrd = Number($(this).attr('data-previndex'));
                  var newOrd = Number(ui.item.index()); // index값이 0에서 시작하기 때문에 1 더하기 

                  const parameter = {
                    oldIndex: oldOrd,
                    newIndex: newOrd
                  }
                  EventBus.$emit('sortableOldNew',parameter)
                }
              });
            }
            

            // 메인 화면 타입 버튼
            /*
            $(".main-view-tab-area .tab-con").each(function(k){
              $(this).find("button").each(function(q){
                $(this).off().on("click", function(){
                  if(!$(this).hasClass("on")){
                    $(this).closest(".tab-con").find("button").removeClass("on");
                    $(this).addClass("on");
                    var k = $(".main-view-tab-area .tab-con.on").index()

                    if(k == 0){
                      $(".dropdown-con .con-div").removeClass("on");
                      $(".dropdown-con .con-div").eq(q).addClass("on");
                    }else{
                      if(q == 3){
                        q = q + 1
                        $(".dropdown-con .con-div").removeClass("on");
                        $(".dropdown-con .con-div").eq(q).addClass("on");
                      }else{
                        $(".dropdown-con .con-div").removeClass("on");
                        $(".dropdown-con .con-div").eq(q).addClass("on");
                      }
                    }
                  }
                });
              })
            })

            // 메인 화면 전체 타입 유형 버튼
            $(".main-view-tab-area .tab-btn-w .tab-btn").each(function(q){
              $(this).off().on("click", function(){
                if(!$(this).hasClass("on")){
                  $(".main-view-tab-area .tab-btn-w .tab-btn").removeClass("on");
                  $(this).addClass("on");
                  $(this).parents(".main-view-tab-area").find(".tab-con").removeClass("on");
                  $(this).parents(".main-view-tab-area").find(".tab-con").eq(q).addClass("on");

                  var index = $(this).parents(".main-view-tab-area").find(".tab-con").eq(q).find("button.on").index();
                  $(".dropdown-con .con-div").removeClass("on")
                  $(".dropdown-con .con-div").eq(index).addClass("on")
                }
              })
            });
            
            */

            // 메인 화면 텍스트 편집 버튼
            $(".acco-sec .dropdown-btn").off().on("click", function(){
              if(!$(this).hasClass("on")){
                $(this).addClass("on");
                $(this).parents(".acco-sec-w").find(".dropdown-con").stop(true, true).slideDown();
              }else{
                $(this).removeClass("on");
                $(this).parents(".acco-sec-w").find(".dropdown-con").stop(true, true).slideUp();
              }
            });

            /*
            $(".border-tab-btn").each(function(){
              $(this).find("button").each(function(){
                $(this).off().on("click", function(){
                  if(!$(this).hasClass("on")){
                    $(this).closest(".border-tab-btn").find("button").removeClass("on");
                    $(this).addClass("on");
                  }
                })
              });
            });
            */

            if($(".slide-wrap").hasClass("img-more")){
              $(".img-more .more-btn").off().on("click", function(){
                $(".slide-wrap").removeClass("img-more")
              })
            }
            
            $(".account-acco .account-list").each(function(){
              if($(this).hasClass("on")){
                $(this).find(".list-con").show();
              }
              $(this).find(".list-tit").off().on("click", function(){
                if(!$(this).parents(".account-list").hasClass("on")){
                  $(this).parents(".account-list").addClass("on");
                  $(this).siblings(".list-con").stop(true, true).slideDown();
                }else{
                  $(this).parents(".account-list").removeClass("on");
                  $(this).siblings(".list-con").stop(true, true).slideUp();
                }
              })
            });
          },
          formFn: function(){
            // 첫 진입 시 검색 input 텍스트 입력되어 있으면 삭제 버튼 노출
            $(".srch-input").each(function(index, item){
              if(!$(item).find("input[type='text']").is('[readonly]') && $(item).find("input[type='text']").val() !== ""){
                $(item).find("input[type='text']").siblings(".input-btn-wrap").find(".delete-btn").show();
              }
            })

            // 검색 input 텍스트 입력 시 삭제 버튼 노출
            $(".srch-input input[type='text']").off().on("keyup", function() {
              if(!$(this).is('[readonly]')){
                if($(this).val() !== "") {
                  $(this).siblings(".input-btn-wrap").find(".delete-btn").show();
                } else {
                  $(this).siblings(".input-btn-wrap").find(".delete-btn").hide();
                }
              }
            });

            // 검색 input 삭제 버튼 클릭 시
            $(".input-btn-wrap .delete-btn").off().on("click", function(){
              $(this).hide();
              $(this).parents(".input-btn-wrap").siblings("input").val("").focus();
            });


            // form select - 화살표 모션
            $(".form-select select").off().focus(function(){
              $(this).parents(".form-select").addClass("active");
            });

            $(".form-select select").blur(function(){
              $(this).data('isopen', false);
              $(this).parents(".form-select").removeClass("active");
            });

            $(".form-select select").off().on("change", function(){
              $(this).parents(".form-select").removeClass("active");
            });
              
            $(".form-select select").off().on("mouseup", function(e) {
              let open = $(this).data("isopen");

              if(open) {
                $(this).parents(".form-select").removeClass("active");
              } else {
                $(this).parents(".form-select").addClass("active");
              }
              
              $(this).data("isopen", !open);
            });

            // 전체 체크박스
            $(".total-check-w").each(function(){
              $(this).find(".form-checkbox").each(function(){
                $(this).find("input[type='checkbox']").off().on("click", function(){
                  if($(this).parent(".form-checkbox").hasClass("total-check")){
                    if($(this).is(":checked")){
                      $(this).parents(".total-check-w").find(".form-checkbox input[type='checkbox']").prop("checked", true);
                    }else{
                      $(this).parents(".total-check-w").find(".form-checkbox input[type='checkbox']").prop("checked", false);
                    }
                  }else{
                    if($(this).parents(".total-check-w").find(".form-checkbox").not(".total-check").find("input[type='checkbox']:checked").length == $(this).parents(".total-check-w").find(".form-checkbox input[type='checkbox']").length - 1){
                      $(this).parents(".total-check-w").find(".total-check input[type='checkbox']").prop("checked", true);
                    }else{
                      $(this).parents(".total-check-w").find(".total-check input[type='checkbox']").prop("checked", false)
                    }
                  }
                })
              })
            });
          },
          scrollFn: function(){
            $(window).off().on("scroll", function(){
              _isScrollTop =  $(window).scrollTop();
              
              // 스크롤 시, 헤더 up & down
              if(_isScrollTop > _this_scroll) { // down
                
              }
            
              if(_isScrollTop < _this_scroll) { // up
                
              }

              _this_scroll = _isScrollTop;
            });
          },
          scrollTriggerFn: function(){
                
          },
          popupFn: function(){
            /*
            $("header.theme .preview-btn").off().on("click", function(){
              $("body").addClass("stop-scroll");
              $(".preview-area").fadeIn();
               $("header.theme").hide();
            });

            $(".preview-area .close-btn").off().on("click", function(){
              $("body").removeClass("stop-scroll");
              $(".preview-area").fadeOut();
               $("header.theme").show();
            });
            */
            
          },
          swiperFn: function(){
            // 큰 텍스트 형식 tab swiper
            if($(".basic-slide").size() > 0){
              if (basicSwiper !== undefined && basicSwiper !== null) {
                basicSwiper.destroy();
                basicSwiper = null;
              }
              basicSwiper = new Swiper(".basic-slide", {
                observer: true,
                observeParents: true,
                loop: true,
                navigation: {
                  nextEl: ".basic-slide .swiper-button-next",
                  prevEl: ".basic-slide .swiper-button-prev",
                },
                pagination: {
                  el: ".basic-slide .swiper-pagination",
                },
              });
            }
            
            if($(".thumb-slide").size() > 0){
              if (swiper !== undefined && swiper !== null) {
                swiper.destroy();
                swiper = null;
              }
              swiper = new Swiper(".thumb-bot-slide", {
                spaceBetween: 10,
                slidesPerView: 5,
                observer: true,
                observeParents: true,
                freeMode: true,
                watchSlidesProgress: true,
              });
              if (swiper2 !== undefined && swiper2 !== null) {
                swiper2.destroy();
                swiper2 = null;
              }
              swiper2 = new Swiper(".thumb-slide", {
                spaceBetween: 10,
                observer: true,
                observeParents: true,
                navigation: {
                  nextEl: ".thumb-slide .swiper-button-next",
                  prevEl: ".thumb-slide .swiper-button-prev",
                },
                thumbs: {
                  swiper: swiper,
                },
              });
            }

            if($(".guest-slide").size() > 0){
              if (basicSwiper2 !== undefined && basicSwiper2 !== null) {
                basicSwiper2.destroy();
                basicSwiper2 = null;
              }
              basicSwiper2 = new Swiper(".guest-slide", {
                observer: true,
                observeParents: true,
                navigation: {
                  nextEl: ".guest-slide .swiper-button-next",
                  prevEl: ".guest-slide .swiper-button-prev",
                },
              });
            }
            
          },
                
          scrollMotionTrigger: function(){
            if($(".scroll-motion").size() > 0){
              $(".scroll-motion:visible").each(function(q){
                let currentEle = $(this);
                gsap.to($(this), {
                  onComplete: function () {
                    // [마이페이지 > 교육 사업 신청내역], progress
                    if($(currentEle).find(".progress-area").length > 0) {
                      let progressValue = $(currentEle).find(".progress-area").data("progress");
                      $(currentEle).find(".progress-area .progress .progress-bar").css("width", `${progressValue}%`);
                      progressCounterNum($(currentEle), progressValue);
                    }
                  },
                  scrollTrigger: {
                    trigger: $(this),
                    start: () => "top 85%",
                    end:"bottom top",
                    toggleClass: {targets: $(".scroll-motion:visible").eq(q), className: "active"},
                    once: true,
                    // markers: true,
                  },
                });
              });
            }
          },

        }
        
    })(),
    };
  },
  computed:{

  },
  created() { },
  mounted() {
    debugger
    this.subScript.init();
    this.subScript.pageInitFn();
    this.subScript.commonMotion();
    this.subScript.formFn();
    this.subScript.scrollFn();
    this.subScript.scrollTriggerFn();
    this.subScript.swiperFn();
    //this.subScript.resizeFn();
    window.removeEventListener('resize', this.handleResize)
    window.addEventListener('resize', this.handleResize);
    this.subScript.popupFn();

    
    /* 2024-03-11 수정 */
    if(window.innerWidth > 1023){
      store.commit('PREVIEW_CLICK',false)
      store.commit('FULL_SCREEN',true)
      
      
    }else{
      store.commit('PREVIEW_CLICK',false)
      store.commit('FULL_SCREEN',false)
    }
    /* // 2024-03-11 수정 */
    
    $(".wedding-wrap .video .video-div").height($(".wedding-wrap .video .video-div").width() * 0.8)


  },
  beforeDestroy(){
  },
  computed: {
  },
  methods: {
        handleResize(){
            $(".wedding-wrap .video .video-div").height($(".wedding-wrap .video .video-div").width() * 0.8)
            /* 2024-03-11 수정 */
            if(window.innerWidth > 1023){
              if(fullscreenFlag === null || !fullscreenFlag){
                store.commit('PREVIEW_CLICK',false)
                store.commit('FULL_SCREEN',true)
                EventBus.$emit('changeMarketPosition')
                EventBus.$emit('changeMarketPosition2')
              }
              fullscreenFlag = true
            }else{
              if(fullscreenFlag === null || fullscreenFlag){
                store.commit('PREVIEW_CLICK',false)
                store.commit('FULL_SCREEN',false)
                EventBus.$emit('changeMarketPosition')
                EventBus.$emit('changeMarketPosition2')
              }
              fullscreenFlag = false
            }
            /* // 2024-03-11 수정 */
            if($(".attend-num-area").size() > 0){
              $(".attend-num-area .bg").height($(".attend-num-area").parents(".txt-con").innerHeight())
              $(".attend-num-area > .top-area").css("width", $(".attend-num-area .bg").width())
            }
        },
    isEmpty(data){
      if(data === null || data === undefined || data === ''){
        return true;
      }else{
        return false;
      }
    },
    getYear(date){
      return moment(date).format('YYYY')
    },
    getMonth(date){
      return moment(date).format('MM')
    },
    getDay(date){
      return moment(date).format('DD')
    },
    getHour(date){
      return moment(date).format('hh')
    },
    getMinute(date){
      return moment(date).format('mm')
    },
    getKorDatetime(date){
     if(this.isEmpty(date)){
        return ''
      }else{
        
        if(date.lastIndexOf(':00:00') == 13){

          return moment(date).format('YYYY년 M월 D일 dddd a h시')
        }else{

          return moment(date).format('YYYY년 M월 D일 dddd a h시 m분')
        }
      }
    },
    getNonYearKorDatetime(date){
     if(this.isEmpty(date)){
        return ''
      }else{
        
        if(date.lastIndexOf(':00:00') == 13){

          return moment(date).format('M월 DD일 dddd a h시')
        }else{

          return moment(date).format('M월 DD일 dddd a h시 m분')
        }
        
      }
    },
    getKor2Datetime(date){
     if(this.isEmpty(date)){
        return ''
      }else{
        var ampm =   moment(date).format('a')==='오전'?'AM':'PM';
        return moment(date).format('YYYY.MM.DD.(ddd) h:mm ') + ampm ;
      }
    },

    
    getYYYYCommaMMCommaDDCommaTime(date){
     if(this.isEmpty(date)){
        return ''
      }else{
        return moment(date).format('YYYY.MM.DD.');
      }
    },
    getYYYYMMDDTime(date){
     if(this.isEmpty(date)){
        return ''
      }else{
        return moment(date).format('YYYY-MM-DD');
      }
    },
    
    getKorTime(date){
     if(this.isEmpty(date)){
        return ''
      }else{

        
        if(date.lastIndexOf(':00:00') == 13){
          return moment(date).format('dddd a h시');
        }else{
          return moment(date).format('dddd a h시 m분');
        }
        
      }
    },
    getDday(date){
        
         const Dday = new Date(date);

        const today = new Date();
        const difference = Dday.getTime() - today.getTime(),
        // Ms 단위로 변환
        secInMs = Math.floor(difference / 1000),
        minInMs = Math.floor(secInMs / 60),
        hourInMs = Math.floor(minInMs / 60),
        days = Math.floor(hourInMs / 24),
        // 남은 시간 계산
        seconds = secInMs % 60,
        minutes = minInMs % 60,
        hours = hourInMs % 24;



      var diffTime = {
        day: days,
        hour:hours,
        minute: minutes,
        second: seconds,
      }


      return diffTime;
    },
    getLastDate(date){
      return moment(date).endOf('month').format('DD')
    },
    getStartDay(date){
      return moment(date).startOf('month').day()
    },
    getLastDay(date){
      return moment(date).endOf('month').day()
    },

    getGroomBrideNameIsEmpty(invitation){
      return this.isEmpty(invitation.invitationInfoGroomLastName) && this.isEmpty(invitation.invitationInfoGroomFirstName) && this.isEmpty(invitation.invitationInfoBrideLastName) && this.isEmpty(invitation.invitationInfoBrideFirstName);
    },
    getTimeAndAddressIsEmpty(invitation){
      return this.isEmpty(invitation.invitationTimeDatetime) && this.isEmpty(invitation.invitationAddressBuildingName) && this.isEmpty(invitation.invitationAddressBuildingFloor);
    },
    getAddressIsEmpty(invitation){
      return this.isEmpty(invitation.invitationAddressBuildingName) && this.isEmpty(invitation.invitationAddressBuildingFloor);
    },
    getParentsIsEmpty(invitation){
      return this.isEmpty(invitation.invitationInfoGroomFatherName) && this.isEmpty(invitation.invitationInfoGroomMotherName) && this.isEmpty(invitation.invitationInfoBrideFatherName)  && this.isEmpty(invitation.invitationInfoBrideMotherName);
    },
    getDieClass(invitation, dieYn){
      if(dieYn === 'N'){
        return ''
      }else{
        if(invitation.invitationInfoDieFlowerYn==='Y'){
          return 'icon-img'
        }else{
          return 'icon-txt'
        }
      }
    },


    getGroomAccountEmpty(invitation){
      return this.isEmpty(invitation.invitationAccountGroomBank) && this.isEmpty(invitation.invitationAccountGroom) && this.isEmpty(invitation.invitationAccountGroomMasterName)
    },
    getGroomFatherAccountEmpty(invitation){
      return this.isEmpty(invitation.invitationAccountGroomFatherBank) && this.isEmpty(invitation.invitationAccountGroomFather) && this.isEmpty(invitation.invitationAccountGroomFatherMasterName)
    },
    getGroomMotherAccountEmpty(invitation){
      return this.isEmpty(invitation.invitationAccountGroomMotherBank) && this.isEmpty(invitation.invitationAccountGroomMother) && this.isEmpty(invitation.invitationAccountGroomMotherMasterName)
    },

    getBrideAccountEmpty(invitation){
      return this.isEmpty(invitation.invitationAccountBrideBank) && this.isEmpty(invitation.invitationAccountBride) && this.isEmpty(invitation.invitationAccountBrideMasterName)
    },
    getBrideFatherAccountEmpty(invitation){
      return this.isEmpty(invitation.invitationAccountBrideFatherBank) && this.isEmpty(invitation.invitationAccountBrideFather) && this.isEmpty(invitation.invitationAccountBrideFatherMasterName)
    },
    getBrideMotherAccountEmpty(invitation){
      return this.isEmpty(invitation.invitationAccountBrideMotherBank) && this.isEmpty(invitation.invitationAccountBrideMother) && this.isEmpty(invitation.invitationAccountBrideMotherMasterName)
    },

    getLineReplace(text){
      var description = text.replace(/(?:\r\n|\r|\n)/g, '<br />')
      description = description.split('\n').join('<br />');

      return description;
    },
    getAddressAndTimeToHtml(text){

      var description = text.replace(',', '<br />')
      return description;
    },
    clickCopyTxt(text){

      let textarea;
      let result;

      try {
        debugger
        textarea = document.createElement('textarea');
        textarea.setAttribute('readonly', true);
        textarea.setAttribute('contenteditable', true);
        textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
        textarea.value = text;

        document.body.appendChild(textarea);

        textarea.focus();
        textarea.select();

        const range = document.createRange();
        range.selectNodeContents(textarea);

        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

        textarea.setSelectionRange(0, textarea.value.length);
        
        result = document.execCommand('copy');
      } catch (err) {
        console.error(err);
        alert(err);
        result = null;
      } finally {
        document.body.removeChild(textarea);
      }

      this.sucessMessage('복사하였습니다.');
      
        
    },deepCopy(o) {
      var result = {};
      if (typeof o === "object" && o !== null){

        for (var i in o) result[i] = this.deepCopy(o[i]);
      }
      else result = o;
      return result;
    },
    makeDisplayUrl(path){
      return process.env.VUE_APP_BASE_API + '/common/display?fileSvrPath='+encodeURI(path)
    },
    previewPossibleDate(date){
      var registDt =  date.substring(0,10) + ' 23:59:59'
      return moment(registDt).add(7,"days").format("YYYY-MM-DD")
    },
    previewPossibleFlag(invitation){
      if(invitation.purchase!==null){
        if(invitation.invitationTimeDatetime===null){
          return true;
        }
        return moment.duration(moment(invitation.invitationTimeDatetime).diff(moment())).asDays() > -30
      }else{
        return true
      }
    },
    dateEndFlag(invitation){
      if(invitation.purchase===null){
        return false;
      }
      if(invitation.invitationTimeDatetime===null){
        return false;
      }
      if(moment.duration(moment(invitation.invitationTimeDatetime).diff(moment())).asDays() < -30){
        return false;
      }

      return moment.duration(moment(invitation.invitationTimeDatetime).diff(moment())).asDays() <0
    },
    dateEnd30Flag(invitation){
      if(invitation.purchase===null){
        return false;
      }
      if(invitation.invitationTimeDatetime===null){
        return false;
      }

      return moment.duration(moment(invitation.invitationTimeDatetime).diff(moment())).asDays() < -30
    },

    eixstIdMakeDisplayUrl(file){
      if(file ===undefined || file ===null){
        return null;
      }
      if(this.isEmpty(file.fileSavePath)){
        return file.url;
      }else{
        return this.makeDisplayUrl(file.fileSavePath)
      }
    },

    sucessMessage(message){
            Message({
              message: message ,
              type: 'success',
              duration: 5 * 1000
            })
    },

    warningMessage(message){

            Message({
              message: message ,
              type: 'warning',
              duration: 5 * 1000
            })
    },
    themeFixed(){
      debugger
      if($(".theme-area").size() > 0){
        ScrollTrigger.matchMedia({
          "(min-width: 1024px)": function() {
            gsap.to($(".theme-area .preview-area .fixed-area"), {
              scrollTrigger: {
                trigger: $(".theme-area .preview-area .fixed-area"),
                // start: "top 70%",
                start: () => "top-=70 top",
                end: () => "bottom bottom",
                endTrigger: $(".cont-wrap"),
                pin:true,
                scrub: true,
                // markers:true,
                onUpdate: self => {
                  const currentScroll = $(".preview-area .scroll-area").scrollTop()
                  self.refresh(); // Refresh ScrollTrigger to update end value when accordion expands/collapses
                  $(".preview-area .scroll-area").scrollTop(currentScroll)
                }
              },
            });
          },
        })
      }
    },
    gallerySortable(){
      
      /* 2024-03-12 추가 */
        $(".img-view").sortable({

                start: function(e, ui) {
                  // 이동 시킬 아이템 클릭 시
                  $(this).attr('data-previndex', ui.item.index()); // 기존 순서값을 data-previndex에 저장 
                },
                update: function(event, ui){
                  var oldOrd = Number($(this).attr('data-previndex'));
                  var newOrd = Number(ui.item.index()); // index값이 0에서 시작하기 때문에 1 더하기 

                  const parameter = {
                    oldIndex: oldOrd,
                    newIndex: newOrd
                  }
                  EventBus.$emit('sortableGalleryOldNew',parameter)
                }
        });
      /* // 2024-03-12 추가 */
    },
    writeDestroy(){
      
      $("body").removeClass("stop-scroll");
      $(".preview-area").fadeOut();
      $("header").show();
      $("header.theme").show();
    }
          
  },
};
</script>

<style>
/*
.layer_popup {
  transition: opacity 0.3s ease !important;
}
*/
</style>