<template>
    <div class="file-btn-area" v-if="file===null && existFileUrl === null">
        <input type="file"  :id="type" :ref="type"  @change="onFileChange" accept="image/gif,image/jpeg,image/png"/>
        <label :for="type"><span>사진 업로드</span></label>
        
    </div>
    <div class="file-img-area" v-else>
        <img :src="url!==null?url:existFileUrl" alt="">
        <button type="button" class="delete-btn" @click="deleteFile"></button>
    </div> 
</template>
<script>
import imageCompression from 'browser-image-compression';
import EventBus from "@/utils/CommonEventBus";
import { Message } from 'element-ui'
export default {
    name: 'CustomInputFile',
    props:['cropRation','type', 'existFileUrl'],
    data() {
        return{
            file:null,
            url:null
        }
    },
    mounted(){
        const context = this
        EventBus.$on("popupEndCrop", (param) => {
            if(context.type === param.type){
                context.file = param.file
                context.url = URL.createObjectURL(context.file);
                const parameter = {
                    file : context.file,
                    url: context.url
                }
                context.$emit('uploadFile',parameter);

            }
        });

    },
    beforeDestroy(){
        EventBus.$off("popupEndCrop");
    },
	methods: {
        async onFileChange(e){
            this.$store.commit('START_LOADING');
            let fileParam = []
            try{
                if(e.target.files.length>0){
                    let file = e.target.files[0];	// 입력받은 file객체
                        // 이미지 resize 옵션 설정 (최대 width을 100px로 지정)
                        const options = { 
                            maxSizeMB: 0.6,
                            maxWidthOrHeight: 1920, // 이미지의 최대 너비 또는 높이
                        }
                        const compressedFile = await imageCompression(file, options);    
                        fileParam.push(compressedFile)
                        const param = {
                            flag: 'imageCropPop',
                            files:fileParam,
                            cropRation:this.cropRation,
                            type:this.type
                        }
                        EventBus.$emit('layerPopup',param)
                }
            }catch(e){
                this.warningMessage('사진 압축에 실패하였습니다. 잠시후 다시 시도해 주세요.');
                fileParam=[];
                console.log(error);
            }
            this.$store.commit('END_LOADING');

        },
        
        deleteFile(){
            this.file = null;
            this.url = null;
            this.$emit('uploadFile',null);
        }
    }
}
</script>