<template>	
  <div class="mind-delivery" v-if="invitation.invitationAccountYn === 'Y'">
		<p :class="['title',scrollMotion]">{{invitation.invitationAccountTitle}}</p>
		<p :class="['txt',scrollMotion]" v-html="getLineReplace(invitation.invitationAccountContent)">
		</p>
		<div :class="['account-acco',scrollMotion]">
			<div :class="['account-list',invitation.invitationAccountGroomOpenYn ==='Y'?'on':'']"><!-- 펼쳐져 있을 경우 on class 추가 -->
				<a href="javascript:" class="list-tit"><span>{{invitation.invitationAccountGroomGroupName}}</span></a>
				<div class="list-con" :style="invitation.invitationAccountGroomOpenYn ==='Y'?'display:block':'display:none'">
					<div class="con" v-if="!getGroomAccountEmpty(invitation)">
						<div class="left">
							<div class="bank-w">
								<div class="top">
									<p class="bank">{{invitation.invitationAccountGroomBank}}</p>
								</div>
									<p class="account">{{invitation.invitationAccountGroom}}</p>
								<p class="name">{{invitation.invitationAccountGroomMasterName}}</p>
							</div>
						</div>
						<div class="right">
							<p class="copy-btn"><a href="javascript:" @click="clickCopyTxt(invitation.invitationAccountGroom)"><span>복사</span></a></p>
							<p v-if="!isEmpty(invitation.invitationAccountGroomKakao)" class="pay-btn"><a :href="invitation.invitationAccountGroomKakao"><img :src="`${publishpath}pub/images/icon-kakao-pay.svg`" alt=""></a></p>
						</div>
					</div>
					<div class="con"  v-if="!getGroomFatherAccountEmpty(invitation)">
						<div class="left">
							<div class="bank-w">
								<div class="top">
									<p class="bank">{{invitation.invitationAccountGroomFatherBank}}</p>
								</div>
									<p class="account">{{invitation.invitationAccountGroomFather}}</p>
								<p class="name">{{invitation.invitationAccountGroomFatherMasterName}}</p>
							</div>
						</div>
						<div class="right">
							<p class="copy-btn"><a href="javascript:" @click="clickCopyTxt(invitation.invitationAccountGroomFather)"><span>복사</span></a></p>
							<p v-if="!isEmpty(invitation.invitationAccountGroomFatherKakao)" class="pay-btn"><a :href="invitation.invitationAccountGroomFatherKakao"><img :src="`${publishpath}pub/images/icon-kakao-pay.svg`" alt=""></a></p>
						</div>
					</div>
					<div class="con"  v-if="!getGroomMotherAccountEmpty(invitation)">
						<div class="left">
							<div class="bank-w">
								<div class="top">
									<p class="bank">{{invitation.invitationAccountGroomMotherBank}}</p>
								</div>
									<p class="account">{{invitation.invitationAccountGroomMother}}</p>
								<p class="name">{{invitation.invitationAccountGroomMotherMasterName}}</p>
							</div>
						</div>
						<div class="right">
							<p class="copy-btn"><a href="javascript:" @click="clickCopyTxt(invitation.invitationAccountGroomMother)"><span>복사</span></a></p>
							<p v-if="!isEmpty(invitation.invitationAccountGroomMotherKakao)" class="pay-btn"><a :href="invitation.invitationAccountGroomMotherKakao"><img :src="`${publishpath}pub/images/icon-kakao-pay.svg`" alt=""></a></p>
						</div>
					</div>
					
				</div>
			</div>
			<div :class="['account-list',invitation.invitationAccountBrideOpenYn ==='Y'?'on':'']">
				<a href="javascript:" class="list-tit"><span>{{invitation.invitationAccountBrideGroupName}}</span></a>
				<div class="list-con" :style="invitation.invitationAccountBrideOpenYn ==='Y'?'display:block':'display:none'">
					<div class="con" v-if="!getBrideAccountEmpty(invitation)">
						<div class="left">
							<div class="bank-w">
								<div class="top">
									<p class="bank">{{invitation.invitationAccountBrideBank}}</p>
								</div>
									<p class="account">{{invitation.invitationAccountBride}}</p>
								<p class="name">{{invitation.invitationAccountBrideMasterName}}</p>
							</div>
						</div>
						<div class="right">
							<p class="copy-btn"><a href="javascript:" @click="clickCopyTxt(invitation.invitationAccountBride)"><span>복사</span></a></p>
							<p v-if="!isEmpty(invitation.invitationAccountBrideKakao)" class="pay-btn"><a :href="invitation.invitationAccountBrideKakao"><img :src="`${publishpath}pub/images/icon-kakao-pay.svg`" alt=""></a></p>
						</div>
					</div>
					<div class="con" v-if="!getBrideFatherAccountEmpty(invitation)">
						<div class="left">
							<div class="bank-w">
								<div class="top">
									<p class="bank">{{invitation.invitationAccountBrideFatherBank}}</p>
								</div>
									<p class="account">{{invitation.invitationAccountBrideFather}}</p>
								<p class="name">{{invitation.invitationAccountBrideFatherMasterName}}</p>
							</div>
						</div>
						<div class="right">
							<p class="copy-btn"><a href="javascript:" @click="clickCopyTxt(invitation.invitationAccountBrideFather)"><span>복사</span></a></p>
							<p v-if="!isEmpty(invitation.invitationAccountBrideFatherKakao)" class="pay-btn"><a :href="invitation.invitationAccountBrideFatherKakao"><img :src="`${publishpath}pub/images/icon-kakao-pay.svg`" alt=""></a></p>
						</div>
					</div>
					<div class="con" v-if="!getBrideMotherAccountEmpty(invitation)">
						<div class="left">
							<div class="bank-w">
								<div class="top">
									<p class="bank">{{invitation.invitationAccountBrideMotherBank}}</p>
								</div>
									<p class="account">{{invitation.invitationAccountBrideMother}}</p>
								<p class="name">{{invitation.invitationAccountBrideMotherMasterName}}</p>
							</div>
						</div>
						<div class="right">
							<p class="copy-btn"><a href="javascript:" @click="clickCopyTxt(invitation.invitationAccountBrideMother)"><span>복사</span></a></p>
							<p v-if="!isEmpty(invitation.invitationAccountBrideMotherKakao)" class="pay-btn"><a :href="invitation.invitationAccountBrideMotherKakao"><img :src="`${publishpath}pub/images/icon-kakao-pay.svg`" alt=""></a></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
export default {
  name: 'WritePreviewAccount',
  props:['invitation','real','scrollMotion'],
  components: {},
  computed:{
  },
  data(){
    return {
    }
  },
  
  created(){
    
  },
  mounted(){
  },
  methods: {

  },

}
</script>
